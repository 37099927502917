import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { LabelDefinition, Strategy } from '@api/types';
import { Button } from '@components/shared/Buttons';

const PromptLabels = ({ definition }: { definition: any }) => {
  const [open, setOpen] = useState<boolean>(false);

  if (definition)
    return (
      <div
        className={`h-min cursor-pointer flex items-center flex-grow flex-col ${open ? 'row-span-5' : ''}`}
        key={`${definition.label}--edit`}
      >
        <Button
          style={`flex flex-row justify-between border-b border-neutral-300 px-3 py-2 w-full ${open ? 'border-none' : 'border-b'}`}
          onClick={() => definition && setOpen(!open)}
          data-testid={`${definition.label}--edit`}
        >
          <div
            className={`line-clamp-1 font-semibold text-md ${open ? 'line-clamp-2' : ''}`}
          >
            {definition.label}
          </div>
          <div className="flex flex-row gap-3 relative">
            {!open ? <FaChevronDown size={15} /> : <FaChevronUp size={15} />}
          </div>
        </Button>
        {open && (
          <div className="px-3 pt-2 max-h-[232px] overflow-y-auto flex flex-col w-full cursor-default">
            <div className="grid grid-cols-4">
              <div>Description</div>
              <div className="col-span-3 border border-neutral-300 rounded-md p-3 mb-3 bg-fg-primary-50 ml-2">
                {definition.description}
              </div>
              <div>Exceptions</div>
              <div className="col-span-3 border border-neutral-300 rounded-md p-3 bg-fg-light ml-2">
                {definition?.exceptions?.join(', ')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

const FixedLabels = ({ labels }: { labels: string[] }) => (
  <div
    className="mb-4 grid gap-4 pt-2 mt-3"
    style={{
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
    }}
  >
    {labels?.map((label: string) => (
      <div
        key={`${label}--list`}
        className="border-b border-neutral-300 px-3 py-2 w-full font-medium overflow-auto"
      >
        {label}
      </div>
    ))}
  </div>
);

const Labels = ({ strategy }: { strategy: Strategy }) => {
  if (strategy?.options?.label_definitions)
    return (
      <div
        className="mb-4 grid gap-4 pt-2"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))'
        }}
      >
        {strategy.options.label_definitions?.map(
          (definition: LabelDefinition) => (
            <PromptLabels
              definition={definition}
              key={`${definition?.label}`}
            />
          )
        )}
      </div>
    );
  if (strategy?.labels)
    return (
      <FixedLabels
        labels={strategy.labels}
        key={`${strategy.labels}--${strategy.slug}`}
      />
    );
};

export { Labels };
