const csColors = {
  black: 'rgb(var(--neutral-1100))',
  gray: 'rgb(var(--gray))',
  lightgray: 'rgb(var(--lightgray))',
  darkblue: 'rgb(var(--darkblue))',
  cyan: 'rgb(var(--cyan))',
  transparent: 'transparent',
  white: 'rgb(var(--white))',
  'custom-bg': 'rgb(var(--custom-bg))',
  'text-white': 'rgb(var(--text-white))',
  'dark-text': 'rgb(var(--dark-text))',
  primary: {
    50: 'rgb(var(--primary-50))',
    100: 'rgb(var(--primary-100))',
    200: 'rgb(var(--primary-200))',
    300: 'rgb(var(--primary-300))',
    400: 'rgb(var(--primary-400))',
    500: 'rgb(var(--primary-500))',
    DEFAULT: 'rgb(var(--primary))',
    600: 'rgb(var(--primary-600))',
    700: 'rgb(var(--primary-700))',
    800: 'rgb(var(--primary-800))',
    900: 'rgb(var(--primary-900))'
  },
  secondary: {
    100: 'rgb(var(--secondary-100))',
    200: 'rgb(var(--secondary-200))',
    300: 'rgb(var(--secondary-300))',
    400: 'rgb(var(--secondary-400))',
    500: 'rgb(var(--secondary-500))',
    600: 'rgb(var(--secondary-600))',
    700: 'rgb(var(--secondary-700))',
    800: 'rgb(var(--secondary-800))',
    900: 'rgb(var(--secondary-900))'
  },
  neutral: {
    100: 'rgb(var(--neutral-100))',
    200: 'rgb(var(--neutral-200))',
    300: 'rgb(var(--neutral-300))',
    400: 'rgb(var(--neutral-400))',
    500: 'rgb(var(--neutral-500))',
    600: 'rgb(var(--neutral-600))',
    700: 'rgb(var(--neutral-700))',
    800: 'rgb(var(--neutral-800))',
    900: 'rgb(var(--neutral-900))',
    1000: 'rgb(var(--neutral-1000))',
    1100: 'rgb(var(--neutral-1100))',
    DEFAULT: 'rgb(var(--neutral))'
  },
  info: {
    light: 'rgb(var(--info-100))',
    DEFAULT: 'rgb(var(--info))',
    dark: 'rgb(var(--info-900))'
  },
  success: {
    light: 'rgb(var(--success-100))',
    DEFAULT: 'rgb(var(--success))',
    dark: 'rgb(var(--success-700))'
  },
  alert: {
    light: 'rgb(var(--alert-100))',
    DEFAULT: 'rgb(var(--alert))',
    dark: 'rgb(var(--alert-700))'
  },
  error: {
    light: 'rgb(var(--error-100))',
    DEFAULT: 'rgb(var(--error))',
    dark: 'rgb(var(--error-700))'
  },
  text: 'rgb(var(--text))',
  bg: {
    1: 'rgb(var(--bg-1))',
    2: 'rgb(var(--bg-2))',
    3: 'rgb(var(--bg-3))',
    4: 'rgb(var(--bg-4))',
    nav: 'rgb(var(--bg-nav))'
  },
  fg: {
    1: 'rgb(var(--fg-1))',
    2: 'rgb(var(--fg-2))',
    3: 'rgb(var(--fg-3))',
    4: 'rgb(var(--fg-4))',
    5: 'rgb(var(--fg-5))',
    6: 'rgb(var(--fg-6))'
  },
  cta: {
    DEFAULT: 'rgb(var(--cta))',
    disabled: 'rgb(var(--cta-disabled))',
    hover: 'rgb(var(--cta-hover))',
    focus: 'rgb(var(--primary-400))',
    active: 'rgb(var(--primary-700))',
    text: 'rgb(var(--cta-white))'
  },
  link: {
    DEFAULT: 'rgb(var(--link))',
    disabled: 'rgb(var(--link-disabled))',
    hover: 'rgb(var(--link-hover))',
    active: 'rgb(var(--link-active))'
  },

  border: 'rgb(var(--border-color))',
  highlight: 'rgb(var(--highlight))',
  btnBg: 'rgb(var(--btnBg))',
  btnBgHover: 'rgb(var(--btnBgHover))',
  btnBgDisabled: 'rgb(var(--btnBgDisabled))',
  btnColor: 'rgb(var(--btnColor))',
  btnColorHover: 'rgb(var(--btnColorHover))',
  btnColorDisabled: 'rgb(var(--btnColorDisabled))',

  btnOutlinedColor: 'rgb(var(--btnOutlinedColor))',
  btnOutlinedColorHover: 'rgb(var(--btnOutlinedColorHover))',
  btnOutlinedColorDisabled: 'rgb(var(--btnOutlinedColorDisabled))'
} as any;

export { csColors };
