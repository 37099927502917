import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiSettings,
  FiLogOut,
  FiList,
  FiChevronsLeft,
  FiChevronsRight,
  FiCompass,
  FiGrid,
  FiHelpCircle,
  FiBox
} from 'react-icons/fi';
import { VscColorMode } from 'react-icons/vsc';
import { MdPolicy } from 'react-icons/md';
import { Auth } from '@aws-amplify/auth';
import { getLogoutURL } from '@utils/user';
import { useUser } from '@context/UserProvider';
import { useQueues } from '@context/QueuesProvider';
import { Dropdown } from '@components/shared/Dropdown';
import { Button } from '@components/shared/Buttons';
import { useAnalytics } from '@context/AnalyticsProvider';

const QueueItem = ({ queue, isCollapsed }: any) => {
  const { platform } = useUser();
  if (!queue) return null;

  const url = `queues/bulklist/${queue.id}?pid=${platform?.id}`;

  return (
    <NavLink
      to={url}
      className="flex justify-between text-sm border-b border-neutral-800 py-1"
      style={({ isActive }) => ({
        ...(isActive ? { pointerEvents: 'none', fontWeight: 600 } : null)
      })}
    >
      <div className="line-clamp-1">{!isCollapsed && queue?.name}</div>
      <div style={isCollapsed ? { marginRight: 0 } : { marginRight: 10 }}>
        {queue?.count}
      </div>
    </NavLink>
  );
};

function Sidebar() {
  const { user, platform, platforms, setPlatform, theme, setTheme } = useUser();
  const SIDEBAR_COLLAPSED_KEY = 'sidebarCollapsed';
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(SIDEBAR_COLLAPSED_KEY) === 'true'
  );
  const [isCollapsing, setIsCollapsing] = useState(false);
  const { mappedQueues } = useQueues();
  const isUserAdmin = user?.isUserAdmin || false;
  const { sendEvent } = useAnalytics();

  const toggleIsCollapsed = () => {
    if (isCollapsed) {
      // Expand
      setIsCollapsed(false);
    } else {
      // Collapse
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsCollapsed(true);
      }, 300);
    }

    localStorage.setItem(SIDEBAR_COLLAPSED_KEY, isCollapsed.toString());
  };

  const redirectOnSignOut = () => {
    const config = Auth.configure();

    const clientId = config.userPoolWebClientId;

    const url = getLogoutURL(config.oauth, clientId || '');

    window.location.assign(url);
  };

  const signOut = () => {
    Auth.signOut({ global: true }).then(
      () => {
        redirectOnSignOut();
      },
      () => {
        redirectOnSignOut();
      }
    );
  };

  const changeTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    sendEvent('clickEvent', { title: `changed theme to ${newTheme}` });
  };

  if (!user) return null;

  return (
    <div className="bg-custom-bg">
      <div
        className={`sidebar ${isCollapsed ? 'sidebar--collapsed' : ''} ${
          isCollapsing ? 'sidebar--isCollapsing' : ''
        }`}
      >
        <div
          className="flex flex-col w-full h-full p-7 text-white navbar"
          style={{ transition: 'all .3s ease-in-out' }}
        >
          <div className="navbar-nav__section">
            <div className="navbar-brand">
              <div>
                <img
                  width="40"
                  height="40"
                  alt="Checkstep"
                  src={`/assets/images/logo.png`}
                />
              </div>
              <div className="nav-link__text">checkstep</div>
            </div>
            <div className="h-[35px] zIndexTop mb-3">
              {isCollapsed && <FiBox color="white" size="30" />}
              {!isCollapsed && (
                <Dropdown
                  title={platform?.name || ''}
                  list={platforms || []}
                  onAction={setPlatform}
                  listStyle="absolute w-[220px] mt-1 overflow-hidden rounded-md"
                  mainClass="bg-cta rounded-md p-0 w-full text-lg ml-2 whitespace-nowrap"
                  buttonStyle="bg-cta text-white p-3 font-semibold rounded-sm"
                  itemStyle="bg-white text-black p-3 overflow:hidden"
                />
              )}
            </div>

            <NavLink to={`/queues?pid=${platform?.id}`}>
              <FiList className="nav-link__icon" />{' '}
              <span className="nav-link__text">Queues</span>
            </NavLink>

            <NavLink to={`/explore?pid=${platform?.id}`}>
              <FiCompass className="nav-link__icon" />{' '}
              <span className="nav-link__text">Explore</span>
            </NavLink>

            {isUserAdmin && (
              <NavLink to={`/dashboard?pid=${platform?.id}`}>
                <FiGrid className="nav-link__icon" />{' '}
                <span className="nav-link__text">Dashboard</span>
              </NavLink>
            )}

            <NavLink to={`/policies?pid=${platform?.id}`}>
              <MdPolicy className="nav-link__icon" />{' '}
              <span className="nav-link__text">Policies</span>
            </NavLink>
            <Button onClick={toggleIsCollapsed} style="flex mt-3 items-center">
              {isCollapsed ? (
                <FiChevronsRight className="nav-link__icon" />
              ) : (
                <FiChevronsLeft className="nav-link__icon" />
              )}{' '}
              <span className="nav-link__text line-clamp-1">
                Minimise Sidebar
              </span>
            </Button>
            <div className="navbar-nav__queuesHeader pt-3">Queues</div>
          </div>
          <div className="navbar-nav__queues">
            {mappedQueues
              ?.filter((x) => !!x?.count)
              .sort((a, b) => (b?.count || 0) - (a?.count || 0))
              .map((x, index) => (
                <QueueItem
                  queue={x}
                  key={x?.id || index}
                  isCollapsed={isCollapsed}
                />
              ))}
          </div>

          <div className="navbar-nav__section navbar-nav__section--bottom">
            <div className="flex justify-between">
              <button className="nav-link__icon" onClick={changeTheme}>
                <VscColorMode />{' '}
              </button>
              <div className="flex justify-between w-full nav-link__text items-center">
                <div
                  className="cursor-pointer flex gap-4 capitalize text-center"
                  onClick={changeTheme}
                >
                  {theme} Theme{' '}
                </div>
                <label className="relative cursor-pointer ml-2 flex items-center">
                  <input
                    type="checkbox"
                    checked={theme === 'light'}
                    onChange={changeTheme}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 rounded-xl bg-neutral-200 text-cta border-cta border-[1.5px] peer peer-checked:after:translate-x-full peer-checked:after:bg-custom-bg after:bg-cta after:absolute after:top-[2px] after:start-[2px] after:rounded-xl after:h-5 after:w-5 after:transition-all peer-checked:bg-cta"></div>
                </label>
              </div>
            </div>
            <NavLink to={`/settings?pid=${platform?.id}`}>
              <FiSettings className="nav-link__icon" />{' '}
              <span className="nav-link__text">Settings</span>
            </NavLink>
            <a
              href="https://checkstep.atlassian.net/servicedesk/customer/portal/1"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <FiHelpCircle className="nav-link__icon" />{' '}
              <span className="nav-link__text">Help</span>
            </a>

            <a onClick={signOut} className="hover:text-cta cursor-pointer">
              <FiLogOut className="nav-link__icon" />{' '}
              <span className="nav-link__text"> Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
